<template>
  <div>
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true"
            >Болих</el-button
          >
          <el-button type="success" :disabled="isDisabled" @click="onPressNewBrand('brandData')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row v-if="!pageStatus.error" v-loading="loading">
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <custom-header :title="'Брэнд засварлах'"/>
        <div class="form-error-container mb20" v-if="errorArray.length != 0">
          <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
          <ul class="error-list">
            <div v-for="(error, index) in errorArray" :key="index">
              <li>{{error.value}}</li>
            </div>
          </ul>
        </div>
        <el-form label-position="top" :model="brandData" :rules="brandRules" ref="brandData">
          <el-row :gutter="20">
            <el-col :span="14">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Монгол нэр" prop="brand_monName">
                        <el-input v-model="brandData.brand_monName" placeholder="Говь ХХК ..."></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Англи нэр" prop="brand_engName">
                      <el-input v-model="brandData.brand_engName" placeholder="Говь ХХК ..."></el-input>
                    </el-form-item>
                  </el-col>
                  </el-row>
                  <el-row :gutter="20">
                      <el-col :span="12">
                      <el-form-item label="Нийлүүлэгч" prop="supplier_id">
                        <el-select
                          disabled
                          v-model="brandData.supplier_id"
                          filterable
                          class="block">
                          <el-option
                            v-for="(supplier, indexSupplier) in supplierOption"
                            :key="indexSupplier"
                            :label="supplier.supplier_monName"
                            :value="supplier.supplier_id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      </el-col>
                        <el-col :span="12">
                          <el-form-item label="Шимтгэлийн хувь" prop="fee_percent">
                      <el-input v-model="brandData.fee_percent" placeholder="Шимтгэлийн хувь"></el-input>
                    </el-form-item>
                        </el-col>
                  </el-row>
                      <el-row>
                      <el-col :span="12">
                        <el-form-item label="Төлөв">
                          <el-switch
                            style="display: block"
                            v-model="brandData.isActive"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            active-text="Идэвхитэй"
                            inactive-text="Идэвхгүй"
                            class="mt10 text-center">
                          </el-switch>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Лого зураг">
                          <el-upload
                            list-type="picture-card"
                            accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                            action=""
                            :on-preview="handlePictureCardPreview"
                            :limit="1"
                            :file-list="tempImages"
                            :on-remove="handleRemove"
                            :auto-upload="false"
                            :on-change="getFile">
                            <span>250x250</span>
                          </el-upload>

                          <loader :active="isUploading" />
                        </el-form-item>
                      </el-col>
                    <el-col :span="24">
                      <el-form-item label="Тайлбар" prop="brand_description">
                        <el-input :rows="3" type="textarea" v-model="brandData.brand_description" placeholder="Улс, түүх"></el-input>
                      </el-form-item>
                    </el-col>
                      </el-row>
                </div>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="panel">
                <div class="panel-item">
                  <header>Ажиллах цагийн хуваарь</header>
                  <!-- <el-form-item :label="day.day" v-for="(day, indexDay) in week" :key="indexDay">
                    <el-time-select
                      :disabled="day.is_weekend"
                      size="mini"
                      placeholder="Нээх цаг"
                      v-model="day.start_time"
                      :picker-options="{
                        start: '08:30',
                        step: '00:15',
                        end: '23:59'
                      }">
                    </el-time-select>
                    <el-time-select
                      class="ml5"
                      size="mini"
                      :disabled="day.is_weekend"
                      placeholder="Хаах цаг"
                      v-model="day.end_time"
                      :picker-options="{
                        start: '08:30',
                        step: '00:15',
                        end: '23:59'
                      }">
                    </el-time-select>
                    <el-tooltip class="item" effect="light" content="Амрах өдөр" placement="top">
                      <el-checkbox class="ml10" v-model="day.is_weekend" @change="generateDateTime(day)"></el-checkbox>
                    </el-tooltip>
                  </el-form-item> -->
                  <header>Брэндийн нийлүүлэгч</header>
                  <el-table
                    :data="brandData.supplier" @cell-click="onPressSupplier">
                    <el-table-column label="Нийлүүлэгч" align="center">
                      <template slot-scope="scope">
                        {{scope.row.supplier_monName}}
                      </template>
                    </el-table-column>
                    <el-table-column label="Регистр" align="center">
                      <template slot-scope="scope">
                        {{scope.row.supplier_regno}}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
               <!-- view history -->
                <div class="panel-item pb20">
                  <el-form-item>
                    <el-button v-if="historyTotal !='0'" type="success" class="block" @click="dialogVisibleHistory = true">Түүх үзэx</el-button>
                  </el-form-item>
                  </div>
            </el-col>
          </el-row>
          <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-button class="text-left" type="danger" @click="onChange('delete')">Устгах</el-button>
              </el-col>
              <el-col :span="16" class="text-right">
                <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
                <el-button type="success" @click="onChange('update')">Шинэчлэх</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <!-- view dialog -->
    <el-dialog title="Түүх харах" :visible.sync="dialogVisibleHistory" width="50%">
        <el-table :data="historyData"
        @cell-click="showHistoryDialog"
        style="width: 100%">
          <el-table-column
          prop="user_name" label="Хэрэглэгч" width="240">
          <template slot-scope="scope">
            {{scope.row.user_name ? scope.row.user_name : 'Нэр оруулаагүй'}}
          </template>
          </el-table-column>
          <el-table-column
          label="Date" prop="created_at" width="180">
          </el-table-column>
          <el-table-column prop="type" label="Үйлдэл"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exitHistory">Буцах</el-button>
      </span>
    </el-dialog>
    <!-- detail view History -->
    <!-- <el-dialog title="Түүх дэлгэрэнгүй харах" :visible.sync="historyDetail" width="60%">
      <el-table
      :data="historyDetailData"
      style="width: 100%">
      <el-table-column
        prop="brand_description" label="Тайлбар">
      </el-table-column>
      <el-table-column
        prop="brand_monName" label="Брэндийн нэр">
      </el-table-column>
      <el-table-column
        prop="brand_engName" label="Brand name">
      </el-table-column>
    </el-table>
    <el-row :gutter="20" class="mt10">
      <el-col align="middle">
        <el-button type="danger" @click="exitHistoryDetail">Хаах</el-button>
      </el-col>
    </el-row>
    </el-dialog> -->
    <el-dialog title="Түүх дэлгэрэнгүй харах" center :visible.sync="historyDetail" :width="historyDetailoldData === null ? '40%' : '80%'">
      <el-row :gutter="20">
      <loader :active="historyLoading" is-screen />
        <el-col :span="12" v-if="historyDetailoldData !== null">
          <el-row :gutter="20">
            <el-card>
              <el-form :model="historyDetailoldData" :disabled="true">
                <el-row :gutter="20">
                  <span><strong>Хуучин</strong> - {{historyDetailoldData.created_at}}</span>
                  <el-tag class="ml20" :type="historyDetailoldData.isActive === false ? 'info' : 'primary'">{{     historyDetailoldData.isActive === false ? 'Идэвхгүй' : 'Идэвхтэй'}}
                  </el-tag>
                </el-row>
                <el-col :span="12">
                  <el-form-item label="Брэнд нэр">
                    <el-input v-model="historyDetailoldData.brand_monName"></el-input>
                  </el-form-item>
                  <el-form-item label="Брэнд лого">
                    <el-image style="width: 200px; height: 200px" :src="`${historyDetailoldData.brand_logo}`" alt=""></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Brand name" >
                    <el-input v-model="historyDetailoldData.brand_engName"></el-input>
                  </el-form-item>
                  <el-form-item label="Брэнд тайлбар">
                    <el-input v-model="historyDetailoldData.brand_description"></el-input>
                  </el-form-item>
                </el-col>
              </el-form>
            </el-card>
          </el-row>
        </el-col>
        <el-col :span="historyDetailoldData === null ? 24 : 12" class="l10" v-if="historyDetailnewData !== null">
          <el-row :gutter="20">
            <el-card>
              <el-form :model="historyDetailnewData" :disabled="true">
                <el-row :gutter="20">
                  <span><strong>Шинэ</strong> - {{historyDetailnewData.created_at}}</span>
                  <el-tag class="ml20" :type="historyDetailnewData.isActive === false ? 'info' : 'primary'">{{historyDetailnewData.isActive === false ? 'Идэвхгүй' : 'Идэвхтэй'}}
                  </el-tag>
                </el-row>
                <el-col :span="12">
                  <el-form-item label="Брэнд нэр" :class="changeLog(historyDetailoldData ? historyDetailoldData.brand_monName : historyDetailnewData.brand_monName, historyDetailnewData.brand_monName) ? 'labelColor' : ''">
                    <el-input v-model="historyDetailnewData.brand_monName"></el-input>
                  </el-form-item>
                  <el-form-item label="Брэнд лого">
                    <el-image style="width: 200px; height: 200px" :src="`${historyDetailnewData.brand_logo}`" alt=""></el-image>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Brand name" :class="changeLog(historyDetailoldData ? historyDetailoldData.brand_engName : historyDetailnewData.brand_engName , historyDetailnewData.brand_engName) ? 'labelColor' : ''">
                    <el-input v-model="historyDetailnewData.brand_engName"></el-input>
                  </el-form-item>
                  <el-form-item label="Брэнд тайлбар" :class="changeLog(historyDetailoldData ? historyDetailoldData.brand_description : historyDetailnewData.brand_description, historyDetailnewData.brand_description) ? 'labelColor' : ''">
                    <el-input v-model="historyDetailnewData.brand_description"></el-input>
                  </el-form-item>
                </el-col>
              </el-form>
            </el-card>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="mt10">
        <el-col align="middle">
          <el-button type="danger" @click="exitHistoryDetail">Хаах</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link :to="'/brandList' + '?page=' + this.$route.query.page + '&size=' + this.$route.query.size + '&search=' + this.$route.query.search + '&field=' + this.$route.query.field + '&sort=' + this.$route.query.sort">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
    <!-- delete -->
    <el-dialog
      :title="isChanging ? 'Брэнд шинэчлэх' : 'Брэнд устгах'"
      :visible.sync="confirmBrandDialog"
      width="50%"
    >
      <div>
        <strong>{{brandData.brand_monName}}</strong> брэндийг {{ isChanging ? 'шинэчлэхдээ' : 'устгахдаа'}} шалтгаанаа заавал бичнэ үү?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmBrandDialog = false" class="mr10"
          >Болих</el-button
        >
        <el-button  v-if="isChanging" :disabled="isDisabled" type="success" @click="onPressNewBrand('brandData', 'brandRules')">
          Хадгалах
        </el-button>
        <el-button v-else type="danger" :disabled="isDisabled" @click="deleteBrand('brandData', 'brandRules')">
          Устгах
        </el-button>
      </span>
      <el-row :gutter="20">
        <el-col :span="24" class="mt20">
          <el-form :model="brandData" :rules="brandRules" ref="brandData">
            <el-form-item prop="desc" label="Шалтгаан">
              <el-input v-model="brandData.desc" type="textarea" :rows="2" :placeholder="isChanging ? 'Шинэчилж буй шалтгаанаа бичнэ үү ...' : 'Устгаж буй шалтгаанаа бичнэ үү ...'"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- Image view dialog -->
    <el-dialog :visible.sync="dialogVisible" title="Брэнд зураг">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import services from '../../../helpers/services'
import customHeader from '../../../components/common/customHeader'
import { handlers } from '../../../helpers/custom'
import loader from '../../../components/common/loader'
export default {
  name: 'brandUpdateForm',
  components: {
    customHeader: customHeader,
    loader: loader
  },

  created () {
    if (this.$route.params.brand_id) {
      this.getOneBrand(this.$route.params.brand_id)
    }
    this.getSuppliers()
  },

  data () {
    return {
      historyDetailoldData: null,
      historyDetailnewData: null,
      historyLoading: false,
      isActive: true,
      historyTotal: '',
      selectedLogId: '',
      selectedLog: [],
      historyDetailData: [],
      historyData: [],
      historyName: 'brand',
      dialogVisibleHistory: false,
      historyDetail: false,
      isDisabled: false,
      isChanging: false,
      tempImages: [],
      isUploading: false,
      confirmBrandDialog: false,
      loading: false,
      confirmLeaveForm: false,
      dialogVisible: false,
      dialogImageUrl: '',
      supplierOption: [],
      brandList: [],
      supplierBrand: [],
      errorArray: [],
      pageStatus: {
        error: false,
        message: ''
      },
      brandData: {
        desc: '',
        brand_id: '',
        brand_monName: '',
        brand_engName: '',
        fee_percent: '',
        supplier_id: '',
        brand_description: '',
        brand_logo: ''
      },
      brandRules: {
        brand_monName: [
          { required: true, message: 'Брэндийн монгол нэрээ оруулна уу', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: 'Заавал бөглөнө үү?', trigger: 'blur' }
        ],
        supplier_id: [
          { required: true, message: 'Нийлүүлэгчээ сонгоно уу', trigger: 'blur' }
        ],
        brand_engName: [
          { required: true, message: 'Брэндийн англи нэрээ оруулна уу', trigger: 'blur' }
        ],
        brand_description: [
          { required: true, message: 'Брэндийн тайлбараа оруулна уу', trigger: 'blur' }
        ]
      }

      // week: [
      //   {
      //     day: 'Даваа',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   },
      //   {
      //     day: 'Мягмар',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   },
      //   {
      //     day: 'Лхагва',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   },
      //   {
      //     day: 'Пүрэв',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   },
      //   {
      //     day: 'Баасан',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   },
      //   {
      //     day: 'Бямба',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   },
      //   {
      //     day: 'Ням',
      //     is_weekend: false,
      //     start_time: null,
      //     end_time: null
      //   }
      // ]
    }
  },
  mounted () {
    if (this.$route.params.brand_id) {
      this.getHistory(this.$route.params.brand_id)
    }
  },
  methods: {
    onPressSupplier (data) {
      this.$router.push({
        name: 'editSupplier',
        params: {
          supplier_id: data.supplier_id
        }
      }).catch(() => {})
    },
    changeLog (old, shine) {
      if (old !== shine) {
        return true
      } else return false
    },
    exitHistory () {
      this.dialogVisibleHistory = false
    },
    exitHistoryDetail () {
      this.historyDetail = false
      this.historyDetailnewData = null
      this.historyDetailoldData = null
    },
    getHistory (brandId) {
      const query = '?id=' + brandId + '&log_name=' + this.historyName
      services.getHistory(query).then(data => {
        this.historyData = data.data
        this.historyTotal = data.total
      })
    },
    getHistoryDetail (data) {
      this.historyLoading = true
      services.getHistoryDetail(data._id).then(response => {
        if (response.status === 'success' && response.data) {
          if (response.data.old) {
            this.historyDetailoldData = response.data.old
          }
          this.historyDetailnewData = response.data.new
          this.historyLoading = false
        } else {
          this.historyLoading = false
        }
      })
    },
    showHistoryDialog (data) {
      this.historyDetail = true
      this.getHistoryDetail(data)
    },
    onChange (type) {
      if (type === 'update') {
        this.confirmBrandDialog = true
        this.isChanging = true
      } else {
        this.confirmBrandDialog = true
        this.isChanging = false
      }
    },

    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },

    getFile (file) {
      const isLt2M = file.size / 1024 / 1024 < 1
      if (!isLt2M) {
        this.$message.error('Зурагны хэмжээ ихдээ 1MB байх ёстой!')
        this.imageNull = 'none'
      } else {
        this.isUploading = true
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            this.isUploading = false
            if (response.status === 'success') {
              this.brandData.brand_logo = response.url
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }
    },

    deleteBrand (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.isDisabled = true
          services.deleteBrand({
            brand_id: this.brandData.brand_id,
            desc: this.brandData.desc
          }).then(response => {
            if (response.status === 'success') {
              this.alertReporter('Амжилттай', `${this.brandData.brand_id} дугаартай брэнд устлаа`, 'success')
              this.loading = false
              this.isDisabled = false
              this.$router.push({
                name: 'brandList'
              })
            } else if (response.status === 'error') {
              this.alertReporter('Алдаа', response.message, 'error')
              this.loading = false
            } else {
              return false
            }
          })
        }
      })
    },

    onPressNewBrand (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          this.isDisabled = true
          const body = JSON.parse(JSON.stringify(this.brandData))
          if (body) {
            body.fee_percent = parseInt(this.brandData.fee_percent)
          }
          if (this.brandData.brand_id !== '') {
            // body.work_time = this.generateWorkTime()
            services.updateBrand(body).then(data => {
              this.alertReporter(data.status === 'success' ? 'Амжилттай' : 'Уучлаарай', data.message, data.status)
              if (data.status === 'success') {
                this.$refs[formName].resetFields()
                this.loading = false
                this.isDisabled = false
                this.$router.push({ name: 'brandList' })
              } else if (data.status === 'error') {
                this.alertReporter('Алдаа', data.message, 'error')
                this.loading = false
              } else {
                return false
              }
            })
          }
        } else {
          console.log('error')
        }
      })
    },

    generateDateTime (day) {
      if (day.is_weekend === true) {
        day.start_time = null
        day.end_time = null
      }
    },

    // generateWorkTime () {
    //   const workingTime = {}
    //   this.week.forEach(day => {
    //     if (day.day === 'Даваа') {
    //       workingTime.monday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Мягмар') {
    //       workingTime.tuesday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Лхагва') {
    //       workingTime.wednesday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Пүрэв') {
    //       workingTime.thursday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Баасан') {
    //       workingTime.friday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Бямба') {
    //       workingTime.saturday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     } else if (day.day === 'Ням') {
    //       workingTime.sunday = {
    //         end_time: day.end_time,
    //         start_time: day.start_time
    //       }
    //     }
    //   })
    //   return workingTime
    // },

    handleRemove (file) {
      var tempId = 0
      this.tempImages.forEach((element, index) => {
        if (element.uid === file.uid) {
          tempId = index
        }
      })
      this.tempImages.splice(tempId, 1)
    },

    getOneBrand (id) {
      services.getOneBrand(id).then(response => {
        if (response.status === 'success') {
          this.brandData = response.data
          this.loading = false
          this.setImages(this.brandData.brand_logo)
          // if (Object.prototype.hasOwnProperty.call(this.brandData, 'work_time')) {
          //   this.week.forEach(day => {
          //     if (day.day === 'Даваа') {
          //       if (this.brandData.work_time.monday.start_time === null || this.brandData.work_time.monday.start_time === null) {
          //         day.is_weekend = true
          //       }
          //       day.start_time = this.brandData.work_time.monday.start_time
          //       day.end_time = this.brandData.work_time.monday.end_time
          //     } else if (day.day === 'Мягмар') {
          //       if (this.brandData.work_time.tuesday.start_time === null || this.brandData.work_time.tuesday.start_time === null) {
          //         day.is_weekend = true
          //       }
          //       day.start_time = this.brandData.work_time.tuesday.start_time
          //       day.end_time = this.brandData.work_time.tuesday.end_time
          //     } else if (day.day === 'Лхагва') {
          //       if (this.brandData.work_time.wednesday.start_time === null || this.brandData.work_time.wednesday.start_time === null) {
          //         day.is_weekend = true
          //       }
          //       day.start_time = this.brandData.work_time.wednesday.start_time
          //       day.end_time = this.brandData.work_time.wednesday.end_time
          //     } else if (day.day === 'Пүрэв') {
          //       if (this.brandData.work_time.thursday.start_time === null || this.brandData.work_time.thursday.start_time === null) {
          //         day.is_weekend = true
          //       }
          //       day.start_time = this.brandData.work_time.thursday.start_time
          //       day.end_time = this.brandData.work_time.thursday.end_time
          //     } else if (day.day === 'Баасан') {
          //       if (this.brandData.work_time.friday.start_time === null || this.brandData.work_time.friday.start_time === null) {
          //         day.is_weekend = true
          //       }
          //       day.start_time = this.brandData.work_time.friday.start_time
          //       day.end_time = this.brandData.work_time.friday.end_time
          //     } else if (day.day === 'Бямба') {
          //       if (this.brandData.work_time.saturday.start_time === null || this.brandData.work_time.saturday.start_time === null) {
          //         day.is_weekend = true
          //       }
          //       day.start_time = this.brandData.work_time.saturday.start_time
          //       day.end_time = this.brandData.work_time.saturday.end_time
          //     } else if (day.day === 'Ням') {
          //       if (this.brandData.work_time.sunday.start_time === null || this.brandData.work_time.sunday.start_time === null) {
          //         day.is_weekend = true
          //       }
          //       day.start_time = this.brandData.work_time.sunday.start_time
          //       day.end_time = this.brandData.work_time.sunday.end_time
          //     }
          //   })
          // }
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа', response.message, 'error')
          this.loading = false
        }
      })
    },

    setImages (images) {
      var tempImages = []
      if (images && Array.isArray(images)) {
        images.forEach(element => {
          tempImages.push({
            url: element
          })
        })
      } else {
        tempImages.push({
          url: images
        })
      }
      this.tempImages = tempImages
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },

    getSuppliers () {
      services.getSuppliers('').then(data => {
        this.supplierOption = data.datas
      })
    }
  }
}
</script>

<style>
.labelColor .el-form-item__label {
  color: red;
}
</style>
